import HttpInterceptor from './HttpInterceptor'

export default {

  name: 'OrganizationInfoService',

  getOrganizationInfo(archived, isParent) {
    if (isParent) {
      return HttpInterceptor.httpClient().get('orginfo/parents?archived=' + archived)
    } else {
      return HttpInterceptor.httpClient().get('orginfo?archived=' + archived)
    }
  },

  addBulletin(bulletin) {
    return HttpInterceptor.httpClient().post('orginfo/bulletin/add', bulletin)
  },

  updateBulletin(bulletin) {
    return HttpInterceptor.httpClient().put('orginfo/bulletin/update', bulletin)
  },

  deleteBulletin(refNr) {
    return HttpInterceptor.httpClient().delete('/orginfo/bulletin/' + refNr + '/delete')
  },
}
