<template>
  <div class="logout-view">
    <div v-if="!isAuthenticated" class="text-justify">
      <md-card>
        <md-card-header class="card-divider">
          <h3><b>Abmeldung</b></h3>
        </md-card-header>
        <md-card-content style="padding-top: 1rem">
          <div v-if="invalidToken" class="text-center">
            <label><b>Session abgelaufen</b></label>
            <label>
              Automatische Abmeldung ausgelöst
            </label>
            <hr>
          </div>

          <div v-if="lastUserName" class="text-center">
            <h5><b>{{lastUserName}}</b></h5>
            <h5>wurde erfolgreich abgemeldet.</h5>
          </div>
          <div v-else-if="!lastUserName" class="text-center">
            <h5>Abmeldung erfolgreich durchgeführt</h5>
          </div>
          <br>
          <div class="cell text-center">
            <div v-if="!isAuthenticated" v-on:click="login" class="button logout"><i class="fi-torso"></i>&nbsp;<i
                class="fi-key"></i>&nbsp;&nbsp;&nbsp;Anmelden
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Login from '@/components/authorization/Login.vue'

  export default {
    name: 'Logout',
    components: {
      Login
    },

    mounted() {
      this.invalidToken = localStorage.getItem('sose.invalid_token') === 'invalid_token';
      localStorage.removeItem('sose.invalid_token');

      if (this.user.firstName) {
        this.lastUserName = this.user.firstName + ' ' + this.user.lastName;
      }
      this.logout();
    },

    data() {
      return {
        lastUserName: null,
        invalidToken: false,
      }
    },

    methods: {

      login() {
        this.$router.push('/Login');
      },

      logout() {
        this.$store.commit('logout');
        this.$router.push('/Logout');
      },

    },

    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },

      user() {
        return this.$store.getters.user;
      },
    },
  }
</script>

<style scoped>
  .logout-view {
    width: 21rem;
    height: 20rem;
    display: inline-block;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -10rem;
    margin-top: -10rem;
  }

  h5 {
    font-weight: 300;
    font-size: large;
  }

  h5 b {
    font-weight: 500;
    color: cadetblue;
  }

  label {
    font-size: larger;
    font-weight: 300;
  }

  label b {
    font-weight: 500;
    color: orangered;
  }

</style>
