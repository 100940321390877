<template>
  <div v-if="!isAuthenticated" class="text-justify">
    <md-card>
      <md-card-header class="card-divider">
        <h3><b>Anmeldung</b></h3>
        <p v-if="$route.query.redirect">
          Sie müssen sich zuerst anmelden.
        </p>
      </md-card-header>
      <md-card-content style="padding-top: 1rem">
        <div v-if="webAppConfig.soseLoginDisabled && magic !== webAppConfig.soseLoginMagic">
          <p style="font-size: larger">
            Lieber SoSE-Nutzer,
            <br><br>
            derzeit steht das System aufgrund von aktuellen <b style="color: seagreen">Wartungsarbeiten</b> nicht zur
            Verfügung.
            Sobald diese beendet sind, können Sie sich hier wieder anmelden.
            <br><br>
            Vielen Dank für Ihr Verständnis.
            <br>
            ai42 Team
          </p>
          <br>
          <md-progress-bar md-mode="buffer"/>
          <br>
        </div>
        <form v-else novalidate @submit.prevent="validateUser">
          <md-field :class="getValidationClass('email')">
            <label for="email">Email</label>
            <md-input type="email" name="email" id="email" autocomplete="email" v-model="form.email"
                      :disabled="sending"/>
            <span class="md-error" v-if="!$v.form.email.required">Email-Adresse ist notwendig</span>
            <span class="md-error" v-else-if="!$v.form.email.email">Ungültige Email-Adresse</span>
          </md-field>
          <md-field :class="getValidationClass('password')">
            <label for="password">Passwort</label>
            <md-input name="password" id="password" autocomplete="passwort" v-model="form.password"
                      :disabled="sending" type="password"/>
            <span class="md-error" v-if="!$v.form.password.required">Passwort ist notwendig</span>
            <span class="md-error"
                  v-if="!$v.form.password.length && $v.form.password.required">Ungültiges Passwort</span>
          </md-field>
          <div v-if="sending">
            <vue-simple-spinner></vue-simple-spinner>
          </div>
          <div v-else>
            <router-link to="/Passwort/Vergessen">
              <div class="text-center"><a>Passwort vergessen</a></div>
              <md-tooltip md-direction="bottom" style="font-size: medium;">Neues Passwort erstellen</md-tooltip>
            </router-link>
            <br>
            <SocialMediaLinks/>
            <br>
            <button class="button expanded" type="submit"><i class="fi-torso"></i>&nbsp;<i class="fi-key"></i>&nbsp;&nbsp;&nbsp;Anmelden
            </button>
          </div>
        </form>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import LoginService from '../../services/LoginService'
import UserService from '../../services/UserService'
import InfoService from '../../services/InfoService'
import HttpErrorHandler from '../../services/HttpErrorHandler'
import HttpInterceptor from '../../services/HttpInterceptor'

import {validationMixin} from 'vuelidate'
import {
  required,
  email,
  minLength
} from 'vuelidate/lib/validators'
import OrganizationService from "../../services/OrganizationService";
import Vue from "vue";
import Address from "../../entities/Address";
import TestAccountData from "../../entities/TestAccountData";
import SocialMediaLinks from "@/components/views/social/SocialMediaLinks";
import OrganizationInfoService from "../../services/OrganizationInfoService";
import HttpUrlService from "../../services/HttpUrlService";

export default {
  name: 'Login',
  mixins: [validationMixin],

  components: {
    SocialMediaLinks,
    'vue-simple-spinner': Spinner
  },

  mounted() {
    this.magic = this.$route.params['magic'];
    this.cleanLocalStorage();
  },

  data: () => ({
    form: {
      email: '',
      password: '',
    },
    sending: false,
    magic: '',

    demand: {
      account: {
        accountOwner: '',
        bankname: '',
        bic: '',
        dateOfMandateGranting: Vue.moment(),
        iban: '',
        mandateReference: '',
        numberOfSuccessfulSEPATransactions: 0,
        valid: true,
        mandateType: 'ONE_OFF'
      },

      advertisingToken: null,
      agbConfirmed: false,
      ai42Contract: null,
      contractConfirmed: true,
      dsgvoConfirmed: false,
      email: '',
      keepDataOfTestphase: false,
      modules: [],
      numChildren: 5,
      organizationAddress: Address.address,
      organizationName: '',
      organizationType: '',
      partnerMember: true,
      password: '',
      paymentInterval: 'MONTHLY',
      testAccountDemand: false,
      testAccountData: TestAccountData.testAccountData,
      useSepaForPayment: true,
    },
  }),

  methods: {

    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.login();
      }
    },

    login() {
      this.sending = true;
      LoginService.login(this.form.email, this.form.password)
        .then(response => {
          this.$store.commit('session', response.data);
          this.getLabels();
        })
        .catch(e => {
          this.sending = false;
          HttpErrorHandler.handleError(e, this, 'Fehler beim Anmelden des Benutzers mit Email ' + this.form.email);
          if (this.isAuthenticated) {
            this.logout();
          }
        });
    },

    cleanLocalStorage() {
      Object.entries(localStorage).forEach(entry => {
        if (entry[1].includes('undefined')) {
          localStorage.removeItem(entry[0]);
        }
      });
    },

    getLabels() {
      InfoService.getLabels()
        .then(response => {
          this.$store.commit('labelsInfo', response.data);
          this.getVersion();
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Labels');
        });
    },

    getVersion() {
      InfoService.getVersion()
        .then(response => {
          this.$store.commit('versionInfo', response.data);
          this.getUserInfo();
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Versionsdaten');
        });
    },

    getUserInfo() {
      UserService.getUserInfo()
        .then(response => {
          this.$store.commit('userInfo', response.data);

          if (this.isSage) {
            this.getPreFilledContract(false);
          }

          if (this.isParent) {
            this.getParentOrganizationInfo();
          }

          if (!this.isSage && !this.isParent) {
            this.getOrganizationInfo();
            this.getOrganizationContract();
            this.getPreFilledContract(true);
          } else {
            this.doRouting();
          }
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Informationen für Benutzer mit Email ' + this.form.email);
          this.sending = false;
          this.logout();
        });
    },

    getOrganizationInfo() {
      OrganizationService.getOrganizationInfo()
        .then(response => {
          this.$store.commit('organizationInfo', response.data);
          this.$store.commit('availableFacilities');
          this.sending = false;
          this.doRouting();
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Trägerdaten');
          this.$store.commit('organizationInfo', {name: '<?>'});
          this.sending = false;
        });
    },

    getOrganizationContract() {
      this.sending = true;
      OrganizationService.getOrganizationContract()
          .then(response => {
            this.$store.commit('organizationContract', response.data);
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Vertragsdaten für Träger <b>' + this.organization.name + '</b>');
            this.sending = false;
          });
    },

    getParentOrganizationInfo() {
      this.sending = true;
      OrganizationInfoService.getOrganizationInfo(false, this.isParent)
        .then(response => {
          this.sending = false;

          let facilities = [];
          const facilityNames = response.data.facilityNames;
          const facilityTypes = response.data.facilityTypes;

          this.user.allowedFacilities.forEach(refNr => {
            facilities.push({
              referenceNumber: refNr,
              name: facilityNames[refNr],
              facilityType: facilityTypes[refNr]
            });
          });

          this.$store.commit('organizationInfo', {facilities: facilities});
          this.$store.commit('availableFacilities');
        })
        .catch(e => {
          HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Trägerdaten');
          this.$store.commit('organizationInfo', {name: '<?>'});
          this.sending = false;
        });
    },

    getPreFilledContract(org) {
      setTimeout(() => {
        this.demand.modules = this.labels.moduleTypes.reverse();
        InfoService.getPreFilledContract(this.demand, org)
          .then(response => {
            this.$store.commit('modules', response.data.ai42Contract.modules);
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden der Modul-Informationen');
          });
      }, 1000);
    },

    isAccessibleRoute(route) {
      if (route.beta) {
        if (!this.isBeta) {
          return false;
        }
      }

      const user = this.user;
      for (let userRole of user.roles) {
        for (let routeRole of route.roles) {
          if (routeRole.startsWith('!')) {
            if (userRole === routeRole.substring(1)) {
              return false;
            }
          } else {
            if (userRole === routeRole) {
              return true;
            }
          }
        }
      }
      return false;
    },

    processChallengeLogin() {
      // Process challenge login
      if (this.challengeLoginRoute.length > 0) {
        let challengeLoginRoute = this.challengeLoginRoute;
        this.$store.commit('challengeLoginRoute', '');
        if (HttpUrlService.isValidRoute(challengeLoginRoute)) {
          if (HttpUrlService.isAccessibleRoute(challengeLoginRoute)) {
            this.$router.push(challengeLoginRoute);
            return true;
          }
        }
      }

      return false;
    },

    doRouting() {
      if (this.processChallengeLogin()) {
        return;
      }

      if (localStorage.getItem(this.user.md5 + '@lastRouterPath')) {
        let lastRoute = localStorage.getItem(this.user.md5 + '@lastRouterPath');
        let routingAllowed = true;
        this.routingBlackList.forEach(route => {
          if (lastRoute.toLowerCase().includes(route.toLowerCase())) {
            console.log('- ' + lastRoute.toLowerCase())
            console.log('= ' + route.toLowerCase())
            routingAllowed = false;
            console.log('Skipping forbidden last route: ' + lastRoute);
          }
        });
        if (routingAllowed) {
          this.$router.push(lastRoute);
          return;
        }
      }

      if (!this.isSage) {
        if (this.isFacility) {
          this.$router.push('/');
          return;
        }
        if (this.isTreasurer) {
          this.$router.push('/Finanzen/Abrechnungen');
          return;
        }
        if (this.isHrmanager) {
          this.$router.push('/Verwaltung/Mitarbeiter');
          return;
        }
        if (this.isParent) {
          this.$router.push('/Aktuelles');
          return;
        }
        if (this.isDocumentation) {
          this.$router.push('/Kinder');
          return;
        }

        this.$router.push('/');

      } else {
        this.$router.push('/Organisationen');
      }
    },

    logout() {
      HttpInterceptor.forceLogout();
      this.$store.commit('logout');
      this.$router.push('/Logout');
    },
  },

  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(5)
      }
    }
  },

  computed: {
    webAppConfig() {
      return this.$store.getters.webAppConfig;
    },
    isAuthenticated() {
      return this.$store.getters.authenticated;
    },
    user() {
      return this.$store.getters.user;
    },
    version() {
      return this.$store.getters.version;
    },
    labels() {
      return this.$store.getters.labels;
    },
    isBeta() {
      return this.$store.getters.beta;
    },
    isSage() {
      return this.$store.getters.sage;
    },
    isAdmin() {
      return this.$store.getters.admin;
    },
    isTreasurer() {
      return this.$store.getters.treasurer;
    },
    isHrmanager() {
      return this.$store.getters.hrmanager;
    },
    isFacility() {
      return this.$store.getters.facility;
    },
    isParent() {
      return this.$store.getters.parent;
    },
    isDocumentation() {
      return this.$store.getters.documentation;
    },
    routingHistory() {
      return this.$store.getters.routingHistory;
    },
    routingBlackList() {
      return this.$store.getters.routingBlackList;
    },
    challengeLoginRoute() {
      return this.$store.getters.challengeLoginRoute;
    },
    navigationRoutes() {
      return this.$store.getters.navigationRoutes;
    },
  }
}
</script>

<style lang="scss" scoped>
input, input:focus {
  border-width: 0;
  box-shadow: none;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: pre-wrap;
}

</style>
