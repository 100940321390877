import HttpInterceptor from "./HttpInterceptor";
import HttpUrlService from "./HttpUrlService";
let base64 = require('base-64');

export default {

  name: 'LoginService',

  login(email, password) {

    const data = { password: base64.encode(password), username: email };

    let timeOut = process.env.VUE_APP_SOSE_SERVER_CONNECT_TIMEOUT_SECONDS * 1000;
    if (sessionStorage.getItem('sose.reconnect')) {
      sessionStorage.removeItem('sose.reconnect');
      timeOut = process.env.VUE_APP_SOSE_SERVER_RECONNECT_TIMEOUT_SECONDS * 1000;
    }

    const config = {
      method: 'POST',
      auth: {
        username: 'ai42sose',
        password: 'Da$wirdderHammer!'
      },
      headers: { 'content-type': 'application/json', 'accept': 'application/json' },
      data: data,
      url: HttpUrlService.getUrl('VUE_APP_SOSE_SERVER_OAUTH_URL'),
      timeout: timeOut,
    };

    return HttpInterceptor.httpClientWithConfig(config);
  },
}
